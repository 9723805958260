$(function () {
  $('#feedback-modal form').submit(function () {
    $('input[name=page_url]', this).val(location)
    $.post('/feedback', $(this).serialize())
    $(this).get(0).reset()
    $('#feedback-modal').modal('hide')
    return false
  })

  $('.feedback-link').click(function () {
    $('#feedback-modal').modal('show')
  })
})
