var EventModel = function (eventData, eventsModel) {
  var self = this

  self.eventData = eventData

  if (eventData.site) {
    self.installation_name = eventData.site.label
    self.installation_code = eventData.site.code
  } else {
    self.installation_name = ''
    self.installation_code = ''
  }

  self.title = ko.computed(function () {
    var response = self.eventData.Source
    if (!response || response.length === 0) {
      response = self.caption()
    }
    return response
  })

  self.message = ko.computed(function () {
    var response = self.eventData.Message
    if (!response || response.length === 0) {
      response = self.caption()
    }
    return response
  })

  self.date = ko.computed(function () {
    var date = moment.unix(self.eventData.RecordTime)
    return date.fromNow() + '<br />' + date.format('MMMM Do YYYY') + '<br />' + date.format('h:mm:ss a')
  })

  self.pulse_type = ko.computed(function () {
    return 'gray'
  })
}

window.EventsModel = function (
    eventData,
    site_filter,
    hide_installation_links,
    can_acknowledge_all,
    totalpages,
    total_event_count
) {
  var self = this
  var eventModels = ko.utils.arrayMap(eventData, function (eventData) { return new EventModel(eventData, self) })
  self.events = ko.observableArray(eventModels)
  self.totalpages = ko.observable(totalpages)
  self.hide_installation_links = hide_installation_links || false

  self.total_event_count = ko.observable(total_event_count)
  self.total_event_count_label = ko.computed(function () {
    return self.total_event_count() + ' event' + (self.total_event_count() !== 1 ? 's' : '')
  }, self)

  self.filter = ko.observable(site_filter)
  self.filter_label = ko.computed(function () {
    return 'All events '
  }, self)

  self.eventsVisible = ko.observable(true)
  self.toggleEventsVisibility = function () {
    self.eventsVisible(!self.eventsVisible())
  }
}
