/* global vm, PIVOT_R1, PIVOT_RELEASE_TAG_PREFIX, TICK_REFRESH_INTERVAL, can_switch_vri_plan, can_control_pivot, CONTROL_TAG_NAME, DIR_ACTIVE_VAL, OptionVM, REFRESH_ALL_TICKS, REFRESH_END_GUN_TICKS, SCADAFARM_API_URL, SET_DISPLAY_TIMEOUT, TRIGGER_CONTROL_TIMEOUT */

var ControlViewModel = function (installation, options) {
  var self = this
  self.installation = installation

    // options
  self.disabled = ko.observable(options.disabled)
  self.heading = options.heading
  self.decimal_places = ('decimal_places' in options) ? options.decimal_places : 0

  self.display = options.display

  self.warning_info = options.warning

  self.template = options.template || 'control-popup-template'

    // bindings
  self.css_class = ko.computed(function () {
    if (self.heading) {
      return self.heading.replace(' ', '-')
    }
    return ''
  })

  if (self.display) {
    self.display.on_label = ko.observable(options.display.on_label || '')
    self.display.off_label = ko.observable(options.display.off_label || '')
  }

  self.refresh_notifier = ko.observable()

  self.options = ko.observableArray(options.options)

  self.current_option = ko.computed(function () {
    var activating_opt = ko.utils.arrayFirst(self.options(), function (opt) {
      return opt.is_activating()
    })
    if (activating_opt) {
      return activating_opt
    }

    return ko.utils.arrayFirst(self.options(), function (opt) {
      return opt.is_on()
    })
  })

  self.new_display_value = ko.observable()
  self.display_is_updating = ko.observable()
  self.display_set_timeout_id = null
  self.error_setting_display = ko.observable()
  self.display_is_refreshing = ko.observable()

  self.display_tag = ko.computed(function () {
    self.refresh_notifier()
    if (self.display && self.display.tag) {
      return self.installation.tag.get_object(self.display.tag)
    }
    return null
  })

  self.remote_value_for_option = function (option_label) {
    self.refresh_notifier()
    var opts = self.options()
    for (var i = 0; i < opts.length; i++) {
      var o = opts[i]
      if (o.label === option_label) {
        var t = self.installation.tag.get_object(o.tag_name)
        if (t) { return t.value }
      }
    }
    return null
  }

  self.old_direction_value = ko.computed(function () {
    self.refresh_notifier()
    var t = self.installation.tag.get_object(PIVOT_RELEASE_TAG_PREFIX + '.RF_FWD')
    if (t && t.Value !== null) {
      if (t.Value === '0') {
        return 'Reverse'
      } else if (t.Value === '1') {
        return 'Forward'
      }
    }
    return null
  })

  function fixedTo (number, n) {
    var k = Math.pow(10, n)
    return (Math.round(number * k) / k)
  }

  self.current_display_value = ko.computed(function () {
    var tag = self.display_tag()
    if (tag) {
      var value = Math.round(tag.data.Value)
      if (self.decimal_places) {
        value = fixedTo(tag.data.Value, self.decimal_places)
      }

      if (self.display_is_updating()) {
        if (value != self.current_display_value()) {
          self.display_is_updating(false)
          self.new_display_value(undefined)
          self.error_setting_display(false)
          window.clearTimeout(self.display_set_timeout_id)
          self.display_finished_updating()
        }
      }

      if (self.display.on_label() && value == 1) {
        return self.display.on_label()
      } else if (self.display.off_label() && value == 0) {
        return self.display.off_label()
      } else {
        return (value)
      }
    }
    return ''
  })

  self.is_off = ko.computed(function () {
    if (self.options().length > 0) {
            // is option off
      var opt = self.current_option()
      return opt && opt.label == 'off'
    }

        // is display tag off
    return self.display && self.display.tag && self.current_display_value() == self.display.off_label() && self.display.off_label() !== '0'
  })

  self.is_on = ko.computed(function () {
    return !self.is_off()
  })

  self.control_value = ko.computed(function () {
    self.refresh_notifier()
    if (!self.display_tag() && self.options()) {
      var opt = self.current_option()
      if (opt) {
        return opt.label
      }
    }

    if (self.display_is_updating() && self.new_display_value() !== undefined) {
      return self.new_display_value()
    }

    return self.current_display_value()
  })

  self.status_overview = ko.computed(function () {
    return self.control_value()
  })

  self.display_value = ko.computed({
    read: function () {
      if (self.new_display_value() !== undefined) {
        return self.new_display_value()
      }
      return self.current_display_value()
    },
    write: function (new_value) {
      if (self.decimal_places) {
        new_value = fixedTo(parseFloat(new_value), self.decimal_places)
      } else {
        new_value = parseInt(new_value)
      }

      var wrap = self.display && self.display.writable && self.display.writable.wrap

      if (new_value > self.max_value()) new_value = wrap ? self.min_value() : self.max_value()
      if (new_value < self.min_value()) new_value = wrap ? self.max_value() : self.min_value()
      self.new_display_value(new_value)
    }
  })

  self.units = ko.computed(function () {
    var tag = self.display_tag()
    if (!tag) { return '' }

    var units = tag.data.Units || ''
    if (units === '˚' || units === '°') {
      return 'deg'
    }

    return units
  })

  self.units_overview = self.units

  self.max_value = ko.computed(function () {
    if (self.display &&
      self.display.writable &&
      self.display.writable.max) {
      return self.display.writable.max
    }

    var tag = self.display_tag()
    if (tag) {
      return tag.data.MaxValue
    }

    return undefined
  })

  self.min_value = ko.computed(function () {
    var tag = self.display_tag()
    if (tag) {
      return tag.data.MinValue
    }

    return undefined
  })

  self.step = ko.computed(function () {
    if (self.display &&
      self.display.writable &&
      self.display.writable.step) {
      return self.display.writable.step
    }
    var max = self.max_value()
    var min = self.min_value()
    if (max != undefined && min != undefined) {
      return Math.ceil((max - min) / 100)
    }
    return 1
  })

  self.activating_options = ko.computed(function () {
    return ko.utils.arrayFilter(self.options(), function (opt) { return opt.is_activating() })
  })
  self.any_activating = ko.computed(function () {
    return self.display_is_updating() || self.activating_options().length > 0
  })

  self.acknowledged_warnings = ko.observable(false)

  self.only_one_option_on = ko.computed(function () {
    var opts_on = ko.utils.arrayFilter(self.options(), function (opt) { return opt.is_on() })
    return opts_on != null && opts_on.length == 1
  })

  self.only_one_option_selected = ko.computed(function () {
    var opts_selected = ko.utils.arrayFilter(self.options(), function (opt) { return opt.is_selected() })
    return opts_selected != null && opts_selected.length == 1
  })

  self.selected_opt = ko.computed(function () {
    if (!self.only_one_option_selected()) {
      return null
    }
    return ko.utils.arrayFirst(self.options(), function (opt) {
      return opt.is_selected()
    })
  })

  self.is_queued = ko.observable(false)

  self.is_control_readonly = ko.computed(function () {
    return self.options().length == 0 && (!self.display || !self.display.writable)
  })

  self.is_popup_open = ko.observable(false)

  self.warning = ko.computed(function () {
    var any_activating = self.any_activating()
    var is_popup_open = self.is_popup_open()

    self.refresh_notifier()
    var warnings = []
    if (self.warning_info) {
      ko.utils.arrayForEach(self.warning_info, function (w) {
        if (
          (w.cancel_while_activating && any_activating) ||
          (w.popup_only_warning && !is_popup_open) ||
          (w.cancel_if_other_warning && warnings.length > 0)
        ) { return }

        var tag = self.installation.tag.get_object(w.tag)
        if (tag && tag.data.Value === w.value) {
          warnings.push(w)
        }
      })
    }
    ko.utils.arrayForEach(self.options(), function (opt) {
      if (opt.error_activating()) {
        warnings.push({ message: '<p>' + opt.label + ' command is taking longer than expected</p>' })
      }
    })
    if (self.error_setting_display()) {
      warnings.push({ message: '<p>Setting the ' + self.display.label + ' is taking longer than expected</p>' })
    }
    return warnings
  })

  self.any_dangerous_warnings = ko.computed(function () {
    return ko.utils.arrayFirst(self.warning(), function (w) {
      return w.dangerous
    }) != null
  })

  self.any_warnings = ko.computed(function () {
    return self.warning().length > 0
  })
  self.any_non_popup_warnings = ko.computed(function () {
    self.refresh_notifier()
    return ko.utils.arrayFirst(self.warning(), function (w) { return !w.popup_only_warning }) != null
  })
  self.any_warnings_blocking_command = ko.computed(function () {
    self.refresh_notifier()
    return ko.utils.arrayFirst(self.warning(), function (w) { return !w.allow_commands }) != null
  })

    // events
  self.open_popup = function (control) {
    if (!can_control_pivot) { return }
    self.is_popup_open(true)

    if (!self.any_warnings()) {
      if (self.is_queued() || self.any_activating()) {
        return
      }
    }

    vm.modifying_control(control)
    var any_activating = self.any_activating()
    ko.utils.arrayForEach(self.options(), function (opt) {
      opt.is_selected(any_activating ? opt.is_activating() : opt.is_on())
    })

    self.acknowledged_warnings(self.warning().length == 0)

    if (self.on_popup_opening) {
      self.on_popup_opening()
    }

    self.new_display_value(undefined)

    $('#control-modal').modal()

    $('#control-modal').on('hide.bs.modal', function () {
      self.is_popup_open(false)
    })
  }

  self.close_popup = function () {
    $('#control-modal').modal('hide')
  }

  self.acknowledge_warnings = function () {
    if (self.any_warnings_blocking_command()) {
      self.close_popup()
    }

    self.acknowledged_warnings(true)
  }

  self.select = function (chosen_option) {
    ko.utils.arrayForEach(self.options(), function (opt) {
      opt.is_selected(opt.label == chosen_option.label)
    })
  }

    // This pure computed makes binding a Control View Model to a select
    // (drop-down) really easy. Your binding will look something like:
    //
    //   data-bind="options: options, optionsText: 'label', value: select_binding"
    //
    // Example: views/templates/installation/control_popup_dropdown.html
  self.select_binding = ko.pureComputed({
    read: function () {
      return self.current_option()
    },
    write: function (option) {
      self.select(option)
    },
    owner: self
  })

  self.update = function () {
    self.close_popup()
    if (vm.any_controls_activating() || vm.activating_controls_queue().length > 0) {
      vm.activating_controls_queue.push(self)
      self.is_queued(true)
    } else {
      self.trigger()
    }
  }

  self.trigger = function () {
    if (self.before_triggering) {
      self.before_triggering()
    }

    self.trigger_set_display()

    if (!self.display_is_updating()) {
      self.trigger_set_option()
    }
  }

  self.trigger_set_display = function () {
    if (!self.display || !self.display.writable) { return }

    if (
      self.new_display_value() === undefined ||
      self.new_display_value() === self.control_value()
    ) { return }

    debug_msg('TRIGGER ' + self.heading + ' => ' + self.new_display_value())

    var raw_value = self.display.writable.set_value_format(self.new_display_value())

    self.display_is_updating(true)
    self.display_set_timeout_id = setTimeout(self.display_set_timeout_callback, SET_DISPLAY_TIMEOUT)

    self.installation.tag.set(self.display.writable.set_value_tag, raw_value).done(function () {
      self.installation.tag.set(self.display.writable.trigger_update_tag, self.display.writable.trigger_update_value)
    })
  }

  self.display_finished_updating = function () {
    self.trigger_set_option()
  }

  self.trigger_set_option = function () {
    var chosen_option = self.selected_opt()
    if (!chosen_option) {
      return
    }

    var only_one_option_on = self.only_one_option_on()

    if (chosen_option == null || (chosen_option.is_on() && only_one_option_on) || self.any_activating()) {
      return
    }

    debug_msg('TRIGGER ' + self.heading + ' => ' + chosen_option.label)

    self.installation.tag.set(chosen_option.control_tag_name, chosen_option.control_tag_value).done(function (tag) {
      chosen_option.is_activating(true)

      ko.utils.arrayForEach(self.options(), function (opt) {
        opt.is_on(false)
        if (opt.label === chosen_option.label) {
          opt.is_activating(true)
          opt.activating_timeout_id = window.setTimeout(self.activation_timeout_callback(opt), TRIGGER_CONTROL_TIMEOUT)
        }
      })

            // command send successfully, waiting to be actioned
    })
  }

  self.activation_timeout_callback = function (opt) {
    return function () {
      debug_msg('timed out activating ' + opt.tag_name + '-' + opt.label)
      opt.error_activating(true)
    }
  }

  self.decrease_display_value = function () {
    self.display_value(self.display_value() - self.step())
  }

  self.increase_display_value = function () {
    self.display_value(self.display_value() + self.step())
  }

  self.decrease_display_value_disable_double_tap_zoom = function (data, event) {
    self.decrease_display_value()
    event && event.preventDefault()
    return false
  }

  self.increase_display_value_disable_double_tap_zoom = function (data, event) {
    self.increase_display_value()
    event && event.preventDefault()
    return false
  }

  self.display_set_timeout_callback = function () {
    debug_msg('timed out setting ' + self.heading + ' display')
    self.error_setting_display(true)
  }

  self.refreshCounter = 0
  self.refresh = function () {
    var refresh_all = !!self.refreshCounter
    var any_activating = self.any_activating()

    self.refreshCounter = (self.refreshCounter + 1) % REFRESH_ALL_TICKS

        // Refresh display
    if (self.display && self.display.tag && (self.display_is_updating() || refresh_all)) {
      debug_msg('refreshing display ' + self.display.tag + ' (' + self.heading + ')')
      var tag = self.installation.tag.get(self.display.tag)
      if (tag) {
        self.refresh_notifier.notifySubscribers()
      }
    }

        // Refresh warnings
    if (self.warning_info && refresh_all) {
      ko.utils.arrayForEach(self.warning_info, function (w) {
        var tag = self.installation.tag.get(w.tag)
        if (tag) { self.refresh_notifier.notifySubscribers() }
      })
    }

        // Refresh options
    ko.utils.arrayForEach(self.options(), function (opt) {
      if (opt.is_activating() || (!any_activating && refresh_all)) {
        if (!opt.refreshing()) {
          opt.refreshing(true)
          debug_msg('refreshing ' + opt.tag_name + '-' + opt.label)
          var tag = self.installation.tag.get_object(opt.tag_name)
          if (tag) {
            self.refresh_notifier.notifySubscribers()
            var is_on = tag && tag.data.Value === opt.on_value
            if (is_on !== opt.is_on() && (opt.is_activating() || opt.error_activating())) {
              if (opt.activating_timeout_id) {
                window.clearTimeout(opt.activating_timeout_id)
                opt.activating_timeout_id = null
              }
              opt.is_activating(false)
              opt.error_activating(false)
            }
            opt.is_on(is_on)
            debug_msg('success refreshing ' + opt.tag_name + ' (' + opt.label + ')')
            opt.refreshing(false)
          } else {
            debug_msg('failed refreshing ' + opt.tag_name + ' (' + opt.label + ')')
            opt.refreshing(false)
          }
        } else {
          debug_msg('still loading ' + opt.tag_name + '-' + opt.label)
        }
      }
    })
  }

  self.refresh()
}

window.StatusViewModel = function (installation, options) {
  var svm = new ControlViewModel(installation, options)

  svm.status_overview = ko.computed(function () {
    return svm.any_non_popup_warnings()
            ? 'wait'
            : svm.control_value()
  })

  return svm
}

window.AutoStopReverseViewModel = function (installation, options) {
  var asrvm = new ControlViewModel(installation, options)

  asrvm.status_overview = ko.computed(function () {
    return asrvm.any_non_popup_warnings()
            ? 'wait'
            : asrvm.control_value()
  })

  return asrvm
}

window.AutoRestartViewModel = function (installation, options) {
  var arvm = new ControlViewModel(installation, options)

  arvm.status_overview = ko.computed(function () {
    return arvm.any_non_popup_warnings()
            ? 'wait'
            : arvm.control_value()
  })

  return arvm
}

var StopSlot = function (sisvm, num, name, angle) {
  var self = this

  self.num = num

  self.current_name = ko.observable(name)
  self.new_name = ko.observable(name)
  self.name = ko.computed(function () {
    if (self.new_name() != undefined) {
      return self.new_name()
    }

    return self.current_name()
  })

  self.current_angle = ko.observable(angle)
  self.new_angle = ko.observable()
  self.angle = ko.computed(function () {
    if (self.new_angle() != undefined) {
      return self.new_angle()
    }

    return self.current_angle()
  })

  self.empty = ko.computed(function () {
    return !self.name()
  })

  self.selected = ko.computed(function () {
    return sisvm.selected_num() == self.num
  })

  self.select = function () {
    sisvm.selected_num(self.num)
    sisvm.new_display_value(self.angle())
  }
}

window.WaterPressureViewModel = function (installation, options) {
  var wpvm = new ControlViewModel(installation, options)

  wpvm.status_overview = ko.computed(function () {
    return wpvm.is_off()
            ? 'off'
            : wpvm.control_value()
  })

  wpvm.units_overview = ko.computed(function () {
    return wpvm.is_off()
            ? ''
            : wpvm.units()
  })

  return wpvm
}

window.SISViewModel = function (installation, options) {
  var sisvm = new ControlViewModel(installation, options)

  sisvm.slots = ko.observableArray()

  var tag_names = []
  var NUM_SIS_SLOTS = 8
  var SIS_LABEL_TAG_PREFIX = PIVOT_RELEASE_TAG_PREFIX + '.SIS.SIS_LAB_'
  var SIS_ANGLE_TAG_PREFIX = PIVOT_RELEASE_TAG_PREFIX + '.SIS.SIS_'

  for (var i = 1; i <= NUM_SIS_SLOTS; i++) {
    tag_names.push(SIS_LABEL_TAG_PREFIX + i)
    tag_names.push(SIS_ANGLE_TAG_PREFIX + i)
  };

  sisvm.selected_num = ko.observable()

  sisvm.selected_slot = ko.computed(function () {
    return ko.utils.arrayFirst(sisvm.slots(), function (slot) {
      return slot.num == sisvm.selected_num()
    })
  })

    // load setup data
  sisvm.start_angle = ko.observable(0)
  sisvm.end_angle = ko.observable(0)

  sisvm.effective_end_angle = ko.computed(function () {
    if (sisvm.start_angle() == 0 && sisvm.end_angle() == 0) {
      return 359
    } else {
      return sisvm.end_angle()
    }
  })

  var tag = installation.tag.get(PIVOT_RELEASE_TAG_PREFIX + '.IC_FWDANG')
  if (tag) {
    if (!isNaN(parseFloat(tag.Value))) {
      sisvm.start_angle(parseFloat(tag.Value))
    }
  }

  tag = installation.tag.get(PIVOT_RELEASE_TAG_PREFIX + '.IC_REVANG')
  if (tag) {
    if (!isNaN(parseFloat(tag.Value))) {
      sisvm.end_angle(parseFloat(tag.Value))
    }
  }

  sisvm.get_slots_done = function (tags) {
    if (tags && tags.length == tag_names.length) {
      for (var i = 0; i < NUM_SIS_SLOTS; i++) {
        var slot_name = tags[i * 2] ? tags[i * 2].Value : null
        var slot_position = tags[i * 2 + 1] ? parseInt(tags[i * 2 + 1].Value) : null
        if (slot_name !== null && slot_position !== null) {
          sisvm.slots.push(new StopSlot(sisvm, i + 1, slot_name, slot_position))
        }
      }
    }
  }

  sisvm.on_popup_opening = function () {
    ko.utils.arrayForEach(sisvm.slots(), function (slot) {
      slot.new_angle(undefined)
      slot.new_name(slot.current_name())
    })

    var selected_slot = ko.utils.arrayFirst(sisvm.slots(), function (slot) {
      return slot.current_angle() == sisvm.current_display_value()
    })

    sisvm.selected_num(selected_slot ? selected_slot.num : 0)
  }

  sisvm.before_triggering = function () {
    ko.utils.arrayForEach(sisvm.slots(), function (slot) {
      if (slot.new_angle() != undefined && slot.new_angle() != slot.current_angle()) {
        debug_msg('set SIS_' + slot.num + ' => ' + slot.new_angle())

        sisvm.installation.tag.set(SIS_ANGLE_TAG_PREFIX + slot.num, slot.new_angle())
        slot.current_angle(slot.new_angle())
        slot.new_angle(undefined)
      }

      if (slot.new_name() != undefined && slot.new_name() != slot.current_name()) {
        debug_msg('set SIS_LAB_' + slot.num + ' => ' + slot.new_name())

        sisvm.installation.tag.set(SIS_LABEL_TAG_PREFIX + slot.num, slot.new_name())
        slot.current_name(slot.new_name())
        slot.new_name(undefined)
      }
    })
  }

  sisvm.new_display_value.subscribe(function (angle) {
    if (sisvm.selected_slot()) {
      sisvm.selected_slot().new_angle(angle)
    }
  })

  sisvm.is_off = ko.computed(function () {
    var opt = sisvm.selected_opt() && sisvm.selected_opt().is_activating() ? sisvm.selected_opt() : sisvm.current_option()
    return opt && opt.label.toLowerCase() == 'off'
  })

  sisvm.status_overview = ko.computed(function () {
    return sisvm.is_off()
            ? 'off'
            : sisvm.control_value()
  })

  sisvm.units_overview = ko.computed(function () {
    if (PIVOT_R1 || sisvm.is_off()) {
      return ''
    }

    return 'deg'
  })

  installation.tag.list(tag_names).done(sisvm.get_slots_done)

  return sisvm
}

ko.extenders.isrequired = function (target, overrideMessage) {
  target.hasError = ko.observable()
  target.validationMessage = ko.observable()

  function validate (newValue) {
    target.hasError(false)
    if (newValue === '' || newValue === undefined || parseInt(newValue) < 0 || parseInt(newValue) > 360) {
      target.hasError(true)
      target.validationMessage(target.hasError() ? overrideMessage || 'Enter valid angle' : '')
    }
  }
  validate(target())
  target.subscribe(validate)

  return target
}

//  used by both endguns and widebounds
var EG_WBSlot = function (gunvm, num, start_ang, stop_ang) {
  var self = this
  self.slot_index = num
  self.current_start_angle = ko.observable(start_ang)
  self.new_start_angle = ko.observable().extend({ isrequired: 'invalid start angle' })
  self.start_angle = ko.computed(function () {
    if (self.new_start_angle.hasError()) {
      return parseInt(self.current_start_angle())
    } else {
      return parseInt(self.new_start_angle())
    }
  })

  self.current_stop_angle = ko.observable(stop_ang)
  self.new_stop_angle = ko.observable().extend({ isrequired: 'invalid stop angle' })
  self.stop_angle = ko.computed(function () {
    if (self.new_stop_angle.hasError()) {
      return parseInt(self.current_stop_angle())
    } else {
      return parseInt(self.new_stop_angle())
    }
  })

  self.empty = ko.computed(function () {
    return !self.new_start_angle()
  })

  self.selected = ko.computed(function () {
    return gunvm.selected_num() === self.slot_index
  })

  self.errorMessage = function () {
    var errors = ['new_start_angle', 'new_stop_angle']
    .filter(function (key) {
      return self[key].hasError()
    })
    .map(function (key) {
      return self[key].validationMessage()
    })
    return errors.length > 1
      ? 'Invalid messages'
      : errors.join(' ')
  }

  self.showError = ko.computed(function () {
    return self.new_start_angle.hasError() || self.new_stop_angle.hasError()
  })

  self.isValid = ko.computed(function () {
    return !self.showError()
  })

  self.checkstate = function () {
    if (self.new_start_angle.hasError()) { self.new_start_angle(self.current_start_angle()) }
    if (self.new_stop_angle.hasError()) { self.new_stop_angle(self.current_stop_angle()) }
  }

  self.select = function () {
    gunvm.selected_num(self.slot_index)
    if (gunvm.selected_num() === self.slot_index && self.isValid() === false) {
      if (self.new_start_angle.hasError()) { self.new_start_angle(self.current_start_angle()) }
      if (self.new_stop_angle.hasError()) { self.new_stop_angle(self.current_stop_angle()) }
    }
  }
}

window.EndGunViewModel = function (installation, options) {
  var endgunvm = new ControlViewModel(installation, options)
  endgunvm.site_code = installation.code
  endgunvm.guntype = 'endgun'
  endgunvm.slots = ko.observableArray()
  endgunvm.angles = []
  var NUM_EG_SLOTS = 9

  endgunvm.selected_num = ko.observable()

  endgunvm.selected_slot = ko.computed(function () {
    return ko.utils.arrayFirst(endgunvm.slots(), function (slot) {
      return slot.slot_index === endgunvm.selected_num()
    })
  })

    // load setup data
  endgunvm.start_display_angle = ko.observable(0)
  endgunvm.stop_display_angle = ko.observable(0)

  endgunvm.get_slots_done = function (angle) {
    for (var i = 0; i < NUM_EG_SLOTS; i++) {
      var slot_start_angle = angle[i] && angle[i].from
      var slot_stop_angle = angle[i] && angle[i].to
      if (slot_start_angle !== null && slot_stop_angle !== null) {
        endgunvm.slots.push(new EG_WBSlot(endgunvm, i + 1, slot_start_angle, slot_stop_angle))
      }
    }
  }

  sf.pivot.get(installation.code).then(function (data) {
    if (data.end_gun) {
      endgunvm.angles = data.end_gun.angles
      endgunvm.get_slots_done(endgunvm.angles)
    }
  })

  endgunvm.on_popup_opening = function () {
    ko.utils.arrayForEach(endgunvm.slots(), function (slot) {
      slot.new_start_angle(slot.current_start_angle())
      slot.new_stop_angle(slot.current_stop_angle())
    })

    var selected_slot = ko.utils.arrayFirst(endgunvm.slots(), function (slot) {
      return slot.selected()
    })

    endgunvm.selected_num(selected_slot ? selected_slot.num : 0)
  }

  endgunvm.before_triggering = function () {
    ko.utils.arrayForEach(endgunvm.slots(), function (slot) {
      if (slot.new_start_angle() !== undefined && slot.new_start_angle() !== slot.current_start_angle()) {
        debug_msg('set EG_' + slot.num + ' => ' + slot.new_start_angle())
      }
      if (slot.new_stop_angle() !== undefined && slot.new_stop_angle() !== slot.current_stop_angle()) {
        debug_msg('set EG_' + slot.num + ' => ' + slot.new_stop_angle())
      }
      //  do posts on all changed slots
      if (slot.new_start_angle() === '' || slot.new_start_angle() === undefined) { slot.new_start_angle(0) }
      if (slot.new_stop_angle() === '' || slot.new_stop_angle() === undefined) { slot.new_stop_angle(0) }

      if (slot.isValid() &&
        slot.new_start_angle() !== slot.current_start_angle() ||
        slot.new_stop_angle() !== slot.current_stop_angle()
      ) {
        sf.post('/pivot/' + endgunvm.site_code + '/' + endgunvm.guntype + '.' + slot.slot_index + '/' + slot.start_angle() + '-' + slot.stop_angle())
          .fail(function (data) {
          //  collect all data return results in an array
          })
          .then(function (data) {
            //  collect all data return results in an array
            slot.current_start_angle(parseInt(slot.new_start_angle()))
            slot.current_stop_angle(parseInt(slot.new_stop_angle()))
          })
      }
    })
  }

  endgunvm.is_off = ko.computed(function () {
    var opt = endgunvm.selected_opt() && endgunvm.selected_opt().is_activating()
      ? endgunvm.selected_opt()
      : endgunvm.current_option()
    return opt && opt.label.toLowerCase() == 'off'
  })

  endgunvm.status_overview = ko.computed(function () {
    return endgunvm.is_off()
      ? 'off'
      : endgunvm.control_value()
  })

  return endgunvm
}

window.WideBoundsViewModel = function (installation, options) {
  var wideboundsvm = new ControlViewModel(installation, options)
  wideboundsvm.site_code = installation.code
  wideboundsvm.guntype = 'wide_bounds'
  wideboundsvm.slots = ko.observableArray()
  wideboundsvm.angles = []
  var NUM_WB_SLOTS = 9

  wideboundsvm.selected_num = ko.observable()

  wideboundsvm.selected_slot = ko.computed(function () {
    return ko.utils.arrayFirst(wideboundsvm.slots(), function (slot) {
      return slot.slot_index == wideboundsvm.selected_num()
    })
  })

  //  load setup data
  wideboundsvm.start_display_angle = ko.observable(0)
  wideboundsvm.stop_display_angle = ko.observable(0)

  wideboundsvm.get_slots_done = function (angle) {
    for (var i = 0; i < NUM_WB_SLOTS; i++) {
      var slot_start_angle = angle[i] && angle[i].from
      var slot_stop_angle = angle[i] && angle[i].to
      if (slot_start_angle !== null && slot_stop_angle !== null) {
        wideboundsvm.slots.push(new EG_WBSlot(wideboundsvm, i + 1, slot_start_angle, slot_stop_angle))
      }
    }
  }

  sf.pivot.get(installation.code).then(function (data) {
    if (data.wide_boundaries) {
      wideboundsvm.angles = data.wide_boundaries.angles
      wideboundsvm.get_slots_done(wideboundsvm.angles)
    }
  })

  wideboundsvm.on_popup_opening = function () {
    ko.utils.arrayForEach(wideboundsvm.slots(), function (slot) {
      slot.new_start_angle(slot.current_start_angle())
      slot.new_stop_angle(slot.current_stop_angle())
    })
    var selected_slot = ko.utils.arrayFirst(wideboundsvm.slots(), function (slot) {
      return slot.selected()
    })
    wideboundsvm.selected_num(selected_slot ? selected_slot.num : 0)
  }

  wideboundsvm.before_triggering = function () {
    ko.utils.arrayForEach(wideboundsvm.slots(), function (slot) {
      if (slot.new_start_angle() !== undefined && slot.new_start_angle() !== slot.current_start_angle()) {
        debug_msg('set WB_' + slot.num + ' => ' + slot.new_start_angle())
      }
      if (slot.new_stop_angle() !== undefined && slot.new_stop_angle() !== slot.current_stop_angle()) {
        debug_msg('set WB_' + slot.num + ' => ' + slot.new_stop_angle())
      }
  // do posts on all changed slots
      if (slot.new_start_angle() === '' || slot.new_start_angle() === undefined) { slot.new_start_angle(0) }
      if (slot.new_stop_angle() === '' || slot.new_stop_angle() === undefined) { slot.new_stop_angle(0) }

      if (slot.isValid() &&
        slot.new_start_angle() !== slot.current_start_angle() ||
        slot.new_stop_angle() !== slot.current_stop_angle()
      ) {
        sf.post('/pivot/' + wideboundsvm.site_code + '/' + wideboundsvm.guntype + '.' + slot.slot_index + '/' + slot.start_angle() + '-' + slot.stop_angle())
          .fail(function (data) {
          //  collect all data return results in an array
          })
          .then(function (data) {
            //  collect all data return results in an array
            slot.current_start_angle(parseInt(slot.new_start_angle()))
            slot.current_stop_angle(parseInt(slot.new_stop_angle()))
          })
      }
    })
  }

  wideboundsvm.is_off = ko.computed(function () {
    var opt = wideboundsvm.selected_opt() && wideboundsvm.selected_opt().is_activating()
    ? wideboundsvm.selected_opt()
    : wideboundsvm.current_option()
    return opt && opt.label.toLowerCase() == 'off'
  })

  wideboundsvm.status_overview = ko.computed(function () {
    return wideboundsvm.is_off()
            ? 'off'
            : wideboundsvm.control_value()
  })

  return wideboundsvm
}

window.AuxControlViewModel = function (installation, options) {
  var default_heading = 'Aux ' + (options.is_output ? 'output' : 'input') + ' ' + options.aux_number
  var name = (options.is_output ? 'OUT' : 'IN') + options.aux_number

  var heading_tag = installation.tag.cached(PIVOT_RELEASE_TAG_PREFIX + '.AUX.IS_' + name + '_DESC')

  var ctrl_options = {
    heading: heading_tag ? heading_tag.data.Value : default_heading,
    disabled: !heading_tag || !heading_tag.data.Value
  }

  if (!ctrl_options.disabled) {
    var feedback_tag_name = PIVOT_RELEASE_TAG_PREFIX + '.AUX.RF_' + name

    var on_label_tag = installation.tag.cached(PIVOT_RELEASE_TAG_PREFIX + '.AUX.IS_' + name + '_ON')
    var off_label_tag = installation.tag.cached(PIVOT_RELEASE_TAG_PREFIX + '.AUX.IS_' + name + '_OFF')

    if (options.is_output) {
      ctrl_options.options = [
        new OptionVM(off_label_tag.data.Value, feedback_tag_name, '0', CONTROL_TAG_NAME, options.off_command),
        new OptionVM(on_label_tag.data.Value, feedback_tag_name, '1', CONTROL_TAG_NAME, options.on_command)
      ]
    } else {
      ctrl_options.display = {
        tag: feedback_tag_name,
        on_label: on_label_tag.data.Value,
        off_label: off_label_tag.data.Value
      }
    }
  }

  return new ControlViewModel(installation, ctrl_options)
}

window.VriPrescriptionViewModel = function (installation) {
  var self = this

  self.installation = installation

  var tag_vri_is_supported = installation.tag.cached(PIVOT_RELEASE_TAG_PREFIX + '.VRI.IB_IS_VRI')
  self.vri_is_supported = tag_vri_is_supported && tag_vri_is_supported.value

  if (self.vri_is_supported) {
    self.vri_waiting_time_seconds = ko.observable(0)
    self.vri_load_time_seconds = 0

    self.vri_last_loaded_index = ko.observable(parseInt(installation.tag.cached(PIVOT_RELEASE_TAG_PREFIX + '.VRI.LAST_LOADED').value))

    self.vri_is_loading = ko.observable(false)

    self.vri_loading_bar_width_percent = ko.computed(function () {
      var retval = null
      if (self.vri_waiting_time_seconds() <= 0) {
        retval = 0
      } else if (self.vri_load_time_seconds <= 0) {
        retval = 0
      } else {
        retval = (self.vri_waiting_time_seconds() / self.vri_load_time_seconds * 100)
      }
      return retval
    })

        // this keeps count of the number of tries we've made to hit the API to check for
        // a change in VRI enabled state
    self.set_vri_enabled_attempt_count = 0

        // the remote setting, updated from the API
    self.is_vri_enabled = ko.observable(installation.tag.cached(PIVOT_RELEASE_TAG_PREFIX + '.VRI.RF_VRIZEN').value == '1')

        // local setting (ie, the setting requested by user) this is the remote setting on first load
    self.is_vri_enabled_local = ko.observable(self.is_vri_enabled())

    self.refresh_vri_is_enabled = function () {
            // if after 6 attempts to find a change in the VRI enabled value there still
            // hasn't been a change, we'll fall back to our local value and exit
      if (self.set_vri_enabled_attempt_count++ == 6) {
        self.is_vri_enabled_local(self.is_vri_enabled())
        return
      }

      var tag = self.installation.tag.get(PIVOT_RELEASE_TAG_PREFIX + '.VRI.RF_VRIZEN')
      if (tag) {
        self.is_vri_enabled(tag.Value == '1')
        if (self.is_vri_enabled() == self.is_vri_enabled_local()) {
                    // do nothing -- the remote value matches the local value
        } else {
          window.setTimeout(self.refresh_vri_is_enabled, 5000)
        }
      } else {
        window.setTimeout(self.refresh_vri_is_enabled, 5000)
      }
    }

    self.vri_sprinkler_bank_count = function () {
      return self.installation.tag.cached(PIVOT_RELEASE_TAG_PREFIX + '.Sprinkler_Banks').value
    }

    self.set_vri_enabled = function (should_enable) {
      if (!can_switch_vri_plan) { return }

      self.set_vri_enabled_attempt_count = 0
      self.is_vri_enabled_local(should_enable)

      var desired_cw_value = 0

      if (should_enable) {
        if (self.installation.tag.cached(PIVOT_RELEASE_TAG_PREFIX + '.VRI.IB_IS_VRIIS').value) {
          desired_cw_value = 272
        } else {
          desired_cw_value = 277
        }
      } else {
        desired_cw_value = 271
      }

      self.installation.tag.set(CONTROL_TAG_NAME, desired_cw_value).done(function () {
        window.setTimeout(self.refresh_vri_is_enabled, 5000)
      }).fail(function () {
                // fail, reset to last-seen remote setting
        self.is_vri_enabled_local(self.is_vri_enabled())
      })
    }

    self.refresh = function () {
      if (self.vri_waiting_time_seconds) {
        if (self.vri_waiting_time_seconds() <= self.vri_load_time_seconds) {
          self.vri_waiting_time_seconds(self.vri_waiting_time_seconds() + (TICK_REFRESH_INTERVAL / 1000))
        } else if (self.vri_is_loading()) {
          installation.tag.get_remote(PIVOT_RELEASE_TAG_PREFIX + '.VRI.LAST_LOADED').done(function (tag) {
            if (tag) {
              self.vri_last_loaded_index(tag.value)
              self.vri_is_loading(false)
            }
          })
        }
      }
    }

    self.vri_prescription_index_selected = ko.observable(self.vri_last_loaded_index())

    self.view_prescription = function (vri_prescription) {
      if (!self.vri_is_loading()) {
        self.vri_prescription_index_selected(vri_prescription.index)
        self.candidate_maps([])
        self.candidate_map_selection([])
      }
    }

    self.selected_prescription = function () {
      var i = self.vri_prescription_index_selected()
      if (i == 0) { i = 1 }
      return self.vri_prescriptions()[i - 1] // prescription indexes are 1-based, array is 0-based
    }

    self.selected_prescription_image_url = function () {
      return self.selected_prescription().image_url
    }

    self.get_prescription_info_from_tag = function (tag, slot_number) {
      var imageOpts = {
        prescriptionCode: slot_number,
        vri: true
      }

      if (tag.value.length === 0) {
        imageOpts.empty = true
      }

      return {
        index: slot_number,

        is_empty: tag.value.indexOf('Blank') !== -1 ||
          tag.value.length === 0,

        name: tag.value.length === 0
          ? slot_number + ' - Blank'
          : slot_number + ' - ' + tag.value,

        image_url: sf.pivot.image_url(installation.code, imageOpts)
      }
    }

    self.vri_prescriptions = ko.observableArray()
    for (var i = 1; i < 11; i++) {
      var tag = installation.tag.cached(PIVOT_RELEASE_TAG_PREFIX + '.VRI.VRI_MAP_' + i)
      self.vri_prescriptions.push(self.get_prescription_info_from_tag(tag, i))
    };

    self.refresh_vri_slot = function (slot_number) {
      installation.tag.get_remote(PIVOT_RELEASE_TAG_PREFIX + '.VRI.VRI_MAP_' + slot_number).done(function (tag) {
        if (tag) {
          self.vri_prescriptions()[slot_number - 1] = self.get_prescription_info_from_tag(tag, slot_number)
          self.vri_prescriptions.valueHasMutated()
        }
      })
    }

    self.candidate_maps = ko.observableArray([])
    self.candidate_map_selection = ko.observableArray([])

    self.confirm_activate_prescription = function () {
      if (!can_switch_vri_plan) { return }

      $('#vri-activate-modal').modal()
    }

    self.activate_prescription = function () {
      var prescription = self.selected_prescription()
      $('#vri-activate-modal').modal('hide')

      self.vri_prescription_index_selected(prescription.index)

            // Set the tag Pivot Release 2a.VRI.VRI_PERSC to the index of the selected prescription (1-10)
      self.installation.tag.set(PIVOT_RELEASE_TAG_PREFIX + '.VRI.VRI_PERSC', prescription.index).done(function (the_tag) {
                // check value is what we expect
        if (the_tag && the_tag.Value != prescription.index) {
          debug_msg('VRI_PERSC was ' + the_tag.Value + ', we expected ' + prescription.index)
        }

                // Set the tag Pivot Release 2a.RC_CW to 600
        self.installation.tag.set(CONTROL_TAG_NAME, '600').done(function () {
                    // Calculate the waiting time in seconds, Next VRI format : 180s, old VRI Format =  6 * Pivot Release 2a.Sprinkler_Banks
          var waiting_time_seconds = (self.installation.tag.cached(PIVOT_RELEASE_TAG_PREFIX + '.VRI.IB_IS_VRIIS').value) ? 180 : (6 * self.installation.tag.cached(PIVOT_RELEASE_TAG_PREFIX + '.Sprinkler_Banks').value)
                    // Just in case we need more time
          waiting_time_seconds += 25

          self.vri_load_time_seconds = waiting_time_seconds

                    // Wait for the waiting time
          self.vri_waiting_time_seconds(0)

          self.vri_is_loading(true)
        })
      })
    }
  }
}

window.PivotWheelViewModel = function (installation, arm_angle_observable, sis_arm_angle_observable, start_angle_observable, end_angle_observable, speed_observable, dir_control) {
  var self = this
  self.installation = installation
  self.dir_control = dir_control

    // helper functions

  self.css_autoprefix_transform = function (transform) {
    return {
      'transform': transform,
      '-webkit-transform': transform,
      '-ms-transform': transform
    }
  }

    // observables

  self.north_offset = ko.observable()

  self.end_gun_on = ko.observable()

  self.arm_angle = arm_angle_observable
  self.sis_arm_angle = sis_arm_angle_observable
  self.start_angle = start_angle_observable
  self.end_angle = end_angle_observable
  self.speed = speed_observable

    // helper observables
  self.is_end_gun_on = function () {
    var tag = self.installation.tag.get(PIVOT_RELEASE_TAG_PREFIX + '.RF_EG')
    return (tag && tag.Value == '1') ? self.end_gun_on(true) : self.end_gun_on(false)
  }

  self.arm_rotation_style = ko.computed(function () {
    var arm_angle = self.arm_angle() + self.north_offset()
    return self.css_autoprefix_transform('rotate(' + arm_angle + 'deg)')
  })

  self.sis_arm_rotation_style = ko.computed(function () {
    var sis_arm_angle = self.sis_arm_angle() + self.north_offset()
    return self.css_autoprefix_transform('rotate(' + sis_arm_angle + 'deg)')
  })

  self.pivot_water_img_src = ko.computed(function () {
    if (self.north_offset() === undefined || (self.start_angle() === 0 && self.end_angle() === 0)) {
      return null
    }
    return SCADAFARM_API_URL + '/pivot-wheel/water-image/' + self.north_offset() + '/' + self.start_angle() + '/' + self.end_angle() + '?size=240'
  })

  self.animation_duration = ko.computed(function () {
        // slower speed means rotate slower, or in CSS terms - longer animation duration
    return ((self.speed() - 1) * -2 / 9 + 30) + 's'
  })

  self.animation_style = ko.computed(function () {
    var is_stopped = self.dir_control.remote_value_for_option('stop') == '0'

    if (is_stopped) {
            // no animation when stopped
      return ''
    } else {
      var is_moving_fwd = self.dir_control.remote_value_for_option('FWD') == DIR_ACTIVE_VAL

            // rotate forwards / backwards depending on status
      var dir = is_moving_fwd ? 'forwards' : 'backwards'
            // slower speed means rotate slower, or in CSS terms - longer animation duration
      var duration = ((self.speed() - 1) * -2 / 9 + 30) + 's'
            // all together now
      return 'spin-' + dir + ' ' + duration + ' infinite linear'
    }
  })

    // refresh data
  self.is_end_gun_on()
  self.refreshCounter = 0
  self.refresh = function () {
    self.refreshCounter = (self.refreshCounter + 1) % REFRESH_END_GUN_TICKS

    if (self.refreshCounter == 0) {
      self.is_end_gun_on()
    }
  }

    // load setup data
  var tag = self.installation.tag.get(PIVOT_RELEASE_TAG_PREFIX + '.IC_ANG_OFFSET')
  if (tag) {
    self.north_offset(tag.Value !== '' ? parseFloat(tag.Value) : 0)
  }

  self.refresh()
}
