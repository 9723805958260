function is_fullscreen () {
  return !window.screenTop && !window.screenY
}

function fullscreen_onchange () {
  var $fs_elems = $('.visible-fullscreen')
  if (is_fullscreen()) {
    $fs_elems.show()
  } else {
    $fs_elems.hide()
  }
}

$(function () {
  $(window).resize(fullscreen_onchange)
  fullscreen_onchange()
})
