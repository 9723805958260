var AlarmModel = function (alarmData, alarmsModel) {
  var self = this

  self.alarmData = alarmData

  if (alarmData.site) {
    self.installation_name = alarmData.site.label
    self.installation_code = alarmData.site.code
  } else {
    self.installation_name = ''
    self.installation_code = ''
  }

  self.hide_installation_link = ko.observable(alarmsModel.hide_installation_links)

  self.caption = ko.computed(function () {
    var split = self.alarmData.AlarmSource.split(',')
    var enterpriseProperty = split.splice(0, 2).join(',')
    var alarmInfo = split.join(',')

    var response = alarmInfo + ' ' + enterpriseProperty
    if (alarmInfo.length === 0 && enterpriseProperty.length === 0) {
      return self.alarmData.Message
    }

    return response
  })

  self.title = ko.computed(function () {
    var response = self.alarmData.AlarmSource
    if (!response || response.length === 0) {
      response = self.caption()
    }
    return response
  })

  self.date = ko.computed(function () {
    var date = moment.unix(self.alarmData.TimeStampUtc)
    return date.fromNow() + '<br />' + date.format('MMMM Do YYYY') + '<br />' + date.format('h:mm:ss a')
  })

  self.accepted = ko.observable(self.alarmData.Accepted)
  self.cleared = ko.observable(self.alarmData.Cleared)

  self.acknowledge = function () {
    sf.alarm.acknowledge(self.alarmData.TagName, self.alarmData.Condition).done(function () {
      self.accepted(true)
    }).fail(function () {
      window.alert('failed to acknowledge alarm')
    })
  }

  self.acknowledge_button_visible = ko.computed(function () {
    if (self.alarmData.TagName.length > 27) {
      var typeCode = parseInt(self.alarmData.TagName.substring(25, 27))
      if (typeCode == 4 || typeCode == 1 || typeCode == 7) {
        return self.title().indexOf('FAULT') != -1
      }
    }
    return true
  })

  self.get_status = function (should_disable) {
    if (self.accepted()) {
      if (!self.alarmData.Cleared) {
        return should_disable ? 'warning disabled' : 'warning'
      }
    } else if (!self.accepted()) {
      if (!self.alarmData.Cleared) {
        return 'danger'
      } else {
        return 'success'
      }
    }

    return ''
  }

  self.status = ko.computed(function () {
    return self.get_status(false)
  })

  self.pulse_type = ko.computed(function () {
    if (self.accepted()) {
      if (!self.alarmData.Cleared) {
        return 'amber animated'
      }
    } else if (!self.accepted()) {
      if (!self.alarmData.Cleared) {
        return 'red animated'
      } else {
        return 'green'
      }
    }

    return ''
  })

  self.shouldDisable = ko.computed(function () {
    return self.accepted() ? 'disabled' : ''
  })
}

window.AlarmsModel = function (
    alarmData,
    site_filter,
    hide_installation_links,
    can_acknowledge_all,
    totalpages,
    total_alarm_count
) {
  var self = this
  var alarmModels = ko.utils.arrayMap(alarmData, function (alarmData) { return new AlarmModel(alarmData, self) })
  self.alarms = ko.observableArray(alarmModels)
  self.totalpages = ko.observable(totalpages)
  self.hide_installation_links = hide_installation_links || false
  self.installation = ko.observable()
  self.total_alarm_count = ko.observable(total_alarm_count)
  self.total_alarm_count_label = ko.computed(function () {
    return self.total_alarm_count() + ' alarm' + (self.total_alarm_count() !== 1 ? 's' : '')
  }, self)

  self.filter = ko.observable(site_filter)
  self.filter_label = ko.computed(function () {
    return 'All alarms '
  }, self)

  self.can_acknowledge_all = can_acknowledge_all

  self.unacknowledged_alarms = ko.computed(function () {
    if (self.installation() && (self.installation().type == 4 || self.installation().type == 1 || self.installation().type == 7)) {
      return ko.utils.arrayFilter(self.alarms(), function (a) {
        return !a.accepted() && a.title().indexOf('FAULT') != -1
      })
    } else {
      return ko.utils.arrayFilter(self.alarms(), function (a) {
        return !a.accepted()
      })
    }
  })

  self.acknowledged_alarms = ko.computed(function () {
    if (self.installation() && (self.installation().type == 4 || self.installation().type == 1 || self.installation().type == 7)) {
      return ko.utils.arrayFilter(self.alarms(), function (a) {
        return a.accepted() || a.title().indexOf('FAULT') == -1
      })
    } else {
      return ko.utils.arrayFilter(self.alarms(), function (a) {
        return a.accepted()
      })
    }
  })

  self.acknowledge_all = function () {
    if (!self.can_acknowledge_all) { return }
    ko.utils.arrayForEach(self.unacknowledged_alarms(), function (a) {
      a.acknowledge()
    })
  }

  self.alarmsVisible = ko.observable(true)
  self.toggleAlarmsVisibility = function () {
    self.alarmsVisible(!self.alarmsVisible())
  }
}
