/* global SCADAFARM_API_URL, graph */
window.InstallationChartModel = function (installation) {
  var self = this

  self.installation = installation
  self.availableChartTimePeriodDays = [2, 7, 20]
  self.chartTimePeriodDays = ko.observable()
  self.isLoading = ko.observable(false)

  self.setChartTimePeriodDays = function (time_period_days) {
        // TODO: loading bar

    self.chartTimePeriodDays(time_period_days)
    self.isLoading(true)

    $.ajax({
      dataType: 'json',
      url: SCADAFARM_API_URL + '/chart-data/' + self.installation.code + '/' + time_period_days,
      xhrFields: {
        withCredentials: true
      },
      success: function (series) {
        document.getElementById('graph').innerHTML = ''
        document.getElementById('y_axis').innerHTML = ''
        document.getElementById('legend').innerHTML = ''
        document.getElementById('slider').innerHTML = ''

        if (series.length === 0) {
          document.getElementById('graph').innerHTML = '<h3>No data available</h3>'
          return
        }

        for (var i = 0; i < series.length; i += 1) {
          var color_idx = i % self.chartColors.length
          series[i].color = self.chartColors[color_idx]
        }

        window.graph = new Rickshaw.Graph({
          element: document.getElementById('graph'),
          renderer: 'line',
          interpolation: 'step-after',
          height: 480,
          series: series
        })

        function nop () {}

        var xAxis = new Rickshaw.Graph.Axis.Time({
          graph: graph,
          timeUnit: {
            name: 'day month',
            seconds: 86400,
            formatter: function (d) {
              return moment(d).format('D MMM')
            }
          }
        })
        xAxis.render()

        nop(new Rickshaw.Graph.Axis.Y({
          graph: graph,
          orientation: 'left',
          color: 'white',
          tickFormat: Rickshaw.Fixtures.Number.formatKMBT,
          element: document.getElementById('y_axis')
        }))

        var legend = new Rickshaw.Graph.Legend({
          graph: graph,
          element: document.getElementById('legend')
        })
        window.legend = legend

        nop(new Rickshaw.Graph.Behavior.Series.Toggle({
          graph: graph,
          legend: legend
        }))

        graph.render()

        nop(new Rickshaw.Graph.RangeSlider({
          graph: graph,
          element: [document.getElementById('slider')]
        }))

        nop(new Rickshaw.Graph.HoverDetail({
          graph: graph,
          formatter: function (series, x, y) {
            var unit = /\((.+)\)/.exec(series.name)[1]
            var name = /(.+) \(/.exec(series.name)[1]
            return name + ': ' + parseInt(y) + ' ' + unit
          },
          xFormatter: function (x) {
            return new Date(x * 1000).toLocaleString()
          }
        }))

                // Shamelessly copied from Underscore
                // http://documentcloud.github.io/underscore/#throttle
        var throttle = function (func, wait, options) {
          var context
          var args
          var result
          var timeout = null
          var previous = 0
          options = options || {}
          var later = function () {
            previous = new Date()
            timeout = null
            result = func.apply(context, args)
          }

          return function () {
            var now = new Date()
            if (!previous && options.leading === false) { previous = now }
            var remaining = wait - (now - previous)
            context = this
            args = arguments
            if (remaining <= 0) {
              clearTimeout(timeout)
              timeout = null
              previous = now
              result = func.apply(context, args)
            } else if (!timeout && options.trailing !== false) {
              timeout = setTimeout(later, remaining)
            }
            return result
          }
        }

        var renderYlabel = function () {
          var units = legend.lines.filter(function removeDisabledLines (l) {
            return !$(l.element).hasClass('disabled')
          }).map(function getUnit (l) {
            return l.series.name.match(/\((.+)\)/)[1].trim()
          }).join(' / ')

          d3.select('svg.y_axis text').remove()
          d3.select('svg.y_axis')
                        .attr('width', '5em')
                        .append('svg:text')
                        .attr('class', 'y label')
                        .style('text-anchor', 'end')
                        .attr('x', -240)
                        .attr('dy', '0.75em')
                        .attr('transform', 'rotate(-90)')
                        .attr('fill', 'white')
                        .text(units)
          this.isRunning = false
        }

                // Need to throttle otherwise it doesn't pick up the right state
        graph.onUpdate(throttle(renderYlabel, 200))
        graph.update()
      }
    }).always(function (data) {
      self.isLoading(false)
    })
  }

  self.chartColors = [
    '#d8d833',
    '#bf1e2e',
    '#d48a38',
    '#0088cc',
    '#fefefe'
  ]
}
