window.WeatherModel = function (info) {
  var self = this
  self.info = ko.observableArray(info)
  self.date = function (info, dt, format) {
    var offset_hours = info.weather.offset
    dt -= (offset_hours * 60 * 60)

    var date = moment.unix(dt)
    return date.format(format).toUpperCase()
  }

  self.windDirection = function (b) {
    if (b >= 337.5 || b <= 22.5) {
      return 'N'
    } else if (b >= 22.5 && b <= 67.5) {
      return 'NE'
    } else if (b >= 67.5 && b <= 112.5) {
      return 'E'
    } else if (b >= 112.5 && b <= 157.5) {
      return 'SE'
    } else if (b >= 157.5 && b <= 202.5) {
      return 'S'
    } else if (b >= 202.5 && b <= 247.5) {
      return 'SW'
    } else if (b >= 247.5 && b <= 292.5) {
      return 'W'
    } else if (b >= 292.5 && b <= 337.5) {
      return 'NW'
    }
  }

  self.precipitationType = function (data) {
    if (data.precipType) {
      return data.precipType.toUpperCase()
    }
    return '&mdash;'
  }

    /*

        Weather bar magic

    */
  self.weatherBarHeightPixels = 100
  self.weatherBarHeight = ko.computed(function () { return self.weatherBarHeightPixels + 'px' })

  self.weatherBarTopPadding = function (parent_data, data) {
    var fmax = self.allDaysMaxTemperature(parent_data.weather.daily.data)
    var fmin = self.allDaysMinTemperature(parent_data.weather.daily.data)

    var dmax = data.temperatureMax

    var ptop = parseInt((fmax - dmax) / (fmax - fmin) * self.weatherBarHeightPixels)
    if (Math.abs(dmax - fmax) < 1) { ptop = 2 }

    return ptop + 'px'
  }

  self.weatherBarBottomPadding = function (parent_data, data) {
    var fmax = self.allDaysMaxTemperature(parent_data.weather.daily.data)
    var fmin = self.allDaysMinTemperature(parent_data.weather.daily.data)

    var dmin = data.temperatureMin

    var pbottom = parseInt((dmin - fmin) / (fmax - fmin) * self.weatherBarHeightPixels)
    if (Math.abs(dmin - fmin) < 1) { pbottom = 2 }

    return pbottom + 'px'
  }

    /*

        Utility functions

    */
  self.allDaysMaxTemperature = function (weather_daily_data) {
    var i
    var max = null
    for (i = 0; i < weather_daily_data.length; i += 1) {
      var f = weather_daily_data[i]
      var fmax = parseInt(f.temperatureMax)
      if (max === null || fmax > max) {
        max = fmax
      }
    }

    return max
  }

  self.allDaysMinTemperature = function (weather_daily_data) {
    var i
    var min = null
    for (i = 0; i < weather_daily_data.length; i += 1) {
      var f = weather_daily_data[i]
      var fmin = parseInt(f.temperatureMin)
      if (min === null || fmin < min) {
        min = fmin
      }
    }

    return min
  }
}
